import React from "react";
import App, { AppProps } from "next/app";
import { MitraProvider } from "../core/context/MitraContext";
import { SaHiringProvider } from "../core/context/SAHiringContext";
import Variables from "../components/styles/variables";
import Fonts from "../components/styles/font";
import Global from "../components/styles/global";
import PosLocatorGlobalStyles from "src/components/styles/pos-locator-global-styles";

// Define the state type
interface State {
  isStylesLoaded: boolean;
}

// Define the component props type, including the Next.js AppProps
interface MyAppProps extends AppProps {}

class _app extends App<MyAppProps, {}, State> {
  constructor(props: MyAppProps) {
    super(props);
    this.state = {
      isStylesLoaded: false,
    };
  }

  componentDidMount() {
    // Use a timeout to simulate waiting for styles to load
    setTimeout(() => {
      this.setState({ isStylesLoaded: true });
    }, 0); // You can adjust the timeout if necessary
  }

  render() {
    const { Component, pageProps, router } = this.props;
    const pathname = router.pathname;
    const { isStylesLoaded } = this.state;

    // Render a loading state while styles are being applied
    if (!isStylesLoaded) {
      return null;
    }

    return (
      <React.Fragment>
        {/* <Script
          dangerouslySetInnerHTML={{
            __html: `   
          var clevertap = {
            event: [],
            profile: [],
            account: [],
            region: "sg1",
            onUserLogin: [],
            notifications: [],
            privacy: []
          };
          clevertap.account.push({ id: "TEST-K57-645-995Z" });
          clevertap.privacy.push({ optOut: false }); //set the flag to true, if the user of the device opts out of sharing their data
          clevertap.privacy.push({ useIP: false }); //set the flag to true, if the user agrees to share their IP data
          (function () {
            var wzrk = document.createElement('script');
            wzrk.type = 'text/javascript';
            wzrk.async = true;
            wzrk.src = ('https:' == document.location.protocol ? 'https://d2r1yp2w7bby2u.cloudfront.net' : 'http://static.clevertap.com') + '/js/a.js';
            var s = document.getElementsByTagName('script')[0];
            s.parentNode.insertBefore(wzrk, s);
          })();
          clevertap.notificationCallback = function(msg){
          clevertap.raiseNotificationViewed();
          console.log(JSON.stringify(msg));
          };
        `
          }}
        /> */}
        <Variables />
        <Fonts />
        {pathname === "/temukan-lokasi-kami" ? (
          <PosLocatorGlobalStyles />
        ) : (
          <Global />
        )}
        <SaHiringProvider>
          <MitraProvider>
            <Component {...pageProps} />{" "}
          </MitraProvider>
        </SaHiringProvider>
      </React.Fragment>
    );
  }
}

export default _app;
